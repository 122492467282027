class RelatedImage {
  constructor (image) {
    this.id = null
    this.image = image
    this.is_published = false
    this.is_main = false
    this.sort_order = null
  }
}

export default RelatedImage
