<template>
  <div>
    <div class="title">{{title}}</div>
    <v-tooltip
      v-model="showTooltipPublic"
      top
    >
      <template v-slot:activator="{ on }">
        <v-btn
          small
          class="pt-1 pb-1"
          @click="handleCopyToClipboard(true)"
        >
          Ссылки на фото публичные
        </v-btn>
      </template>
      <span>Скопировано</span>
    </v-tooltip>
    <v-tooltip
      v-model="showTooltipService"
      top
    >
      <template v-slot:activator="{ on }">
        <v-btn
          small
          class="pt-1 pb-1"
          @click="handleCopyToClipboard(false)"
        >
          Ссылки на фото служебные
        </v-btn>
      </template>
      <span>Скопировано</span>
    </v-tooltip>
    <v-btn
      small
      class="pt-1 pb-1"
      @click="handlePublish"
    >
      Опубликовать все фото
    </v-btn>
    <!--Фото элемент-->
    <div class="d-flex flex-wrap">
      <v-card
        class="mx-0 mt-3 d-flex justify-lg-space-around flex-column align-self-center align-center"
        min-width="200"
        min-height="300"
      >
        <v-card-text class="text-center">
          <div class="text-center subtitle-1">загрузить фото</div>
          <v-btn
            outlined
            fab
            color="indigo"
            @click="$refs.fileInput.click()"
          >
            <input
              ref="fileInput"
              id="fileInput"
              type="file"
              multiple
              :name="uploadFieldName"
              :disabled="isSaving"
              @change="filesChange($event.target.name, $event); fileCount = $event.target.files.length"
              accept="image/*"
              class="input-file"
            >
            <v-icon large>mdi-plus</v-icon>
          </v-btn>
          <span
            v-if="fileSizeError"
            class="d-block red--text mt-3"
            style="width: 180px; text-align: center"
          >{{fileSizeError}}</span>
        </v-card-text>
      </v-card>
      <v-card
        v-for="(image, index) in uploadedFiles"
        :key="index+'-'+image.id"
        class="mx-0 mt-3"
        max-width="200"
      >
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <div class="d-flex positionTop">
                <div>
                  <v-icon title="Основная фотография">{{image.is_main ? 'mdi-file-image' : ''}}</v-icon>
                </div>
                <div>
                  <v-icon title="Опубликовано">{{image.is_published ? 'mdi-image-plus' : ''}}</v-icon>
                </div>
              </div>
              <div class="prevImg">
                <v-menu
                  :close-on-content-click="false"
                  offset-x
                >
                  <template v-slot:activator="{ on }">
                    <v-img
                      :src="axios.defaults.baseURL + '/cache/images/thumbnails/' + image.image.path + image.image.file_name"
                      v-on="on"
                    ></v-img>
                  </template>
                  <div class="position-fixed">
                    <div class="modal">
                      <v-btn
                        class="closedImgOverlay primary"
                        icon
                        @click="overlay = false"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                      <v-img
                        width="500"
                        :src="axios.defaults.baseURL + '/images/' + image.image.path + image.image.file_name"
                      ></v-img>
                    </div>
                  </div>
                </v-menu>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              class="text-right"
            >
              <v-menu
                :close-on-content-click="false"
                offset-x
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mr-3"
                    small
                    v-on="on"
                  >
                    Меню
                    <v-icon small>mdi-dots-horizontal</v-icon>
                  </v-btn>
                </template>
                <v-card width="290">
                  <v-card-text>
                    <div
                      v-if="image.publishedBy"
                      class="caption pr-6"
                    >Опубликовал(а): <b>{{image.publishedBy.username}}</b>
                    </div>
                    <v-checkbox
                      v-model="image.is_published"
                      @change="$emit('update:image.is_published', $event)"
                      label="Отображать в публичной части"
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-if="$can('admin') || $can('agent')"
                      v-model="image.is_main"
                      label="Сделать основной"
                      hide-details
                    ></v-checkbox>
                    <v-text-field
                      v-model="image.sort_order"
                      type="number"
                      label="Порядок сортировки"
                      style="width: 100px"
                    ></v-text-field>
                    <v-btn
                      text
                      @click="handleRemove(index)"
                      :disabled="!$can('agent', 'removePhoto')"
                    >
                      <v-icon>mdi-delete</v-icon>
                      Удалить
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import * as Config from '@/config'
import formatters from '@/components/mixins/formatters'
import RelatedImage from '@/components/structures/relatedImage'

export default {
  mixins: [formatters],
  name: 'ImageUploader',
  props: {
    title: String,
    value: Array,
    url: String
  },
  data () {
    return {
      uploadFieldName: 'photos',
      isUploading: false,
      images: this.value,
      fileCount: 0,
      fileSizeError: null,
      showTooltipPublic: false,
      showTooltipService: false
    }
  },
  computed: {
    isSaving () {
      return this.isUploading
    },
    uploadedFiles () {
      return this.images
    }
  },
  watch: {
    images: {
      handler () {
        this.$emit('input', this.images)
      },
      deep: true
    },
    value: {
      handler () {
        this.images = this.value
      },
      deep: true
    }
  },
  methods: {
    activeLinks (isPublic = true) {
      let links = []
      let url = isPublic ? '/cache/images/' : '/images/'
      this.images.forEach(item => {
        if (!isPublic || item.is_published) {
          let link = this.axios.defaults.baseURL + url + item.image.path + item.image.file_name
          links.push(link)
        }
      })
      return links.length ? links.join('\n') : ' '
    },
    async filesChange (fieldName, fileList) {
      this.fileSizeError = null
      fileList = fileList.target.files
      if (!fileList.length) return
      for (let key in fileList) {
        if (fileList[key].size && fileList[key].size > Config.maxFileSize) {
          this.fileSizeError = 'Размер файла не должен превышать ' + this.bytesToSize(Config.maxFileSize)
        }
      }
      if (this.fileSizeError) {
        return
      }
      const formData = new FormData()

      for (let i = 0; i < fileList.length; i++) {
        let file = fileList[i]
        formData.append('files[' + i + ']', file)
      }

      await this.save(formData)
    },
    async save (data) {
      this.isUploading = true

      const response = await this.$store.dispatch('imageUploader/save', { url: this.url, data })
      if (response) {
        this.images = this.images.concat(this.processResponse(response))
        this.$emit('input', this.images)
      }
      this.isUploading = false
    },
    processResponse (response) {
      let result = []
      response.forEach(image => result.push(new RelatedImage(image)))
      return result
    },
    handleRemove (index) {
      this.images.splice(index, 1)
    },
    async handleCopyToClipboard (isPublic) {
      await this.$copyText(this.activeLinks(isPublic))
      let tooltip = 'showTooltip'
      tooltip = isPublic ? tooltip + 'Public' : tooltip + 'Service'
      this[tooltip] = true
      setTimeout(() => {
        this[tooltip] = false
      }, 1000)
    },
    handlePublish () {
      this.images.forEach(image => {
        image.is_published = true
      })
      return this.images
    }
  }
}
</script>

<style scoped>
  .input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 200px;
    position: absolute;
    cursor: pointer;
  }
  .closedImgOverlay {
    position: fixed;
    right: 5%;
    top: 30px;
  }
  .prevImg {
    overflow: hidden;
    width: 170px;
    height: 170px;
    display: flex;
    justify-content: center;
  }
  .prevImg img {
  }
  .positionTop {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
  }
  .positionTop div {
    display: block;
    margin: 5px;
  }
</style>
